import React, { useState, useEffect } from "react"

import HomepagePagePage from "../templates/Homepage"
import DefaultPagePage from "../templates/Healthspan Default page"
import withLocation from "../components/withLocation"
import Cookies from "js-cookie"

const AccroPress = require("@accrosoft-ltd/accropress-node-sdk")

function CMSContentPreview({ search }) {
  const { pageid, isRevision, siteid } = search

  const [cmsData, setCmsData] = useState(0)
  const [cmsError, setCmsError] = useState(null)
  const [cmsLoading, setCmsLoading] = useState(true)

  const previewCookie = Cookies.get("accropress_cms_preview_jwt") || Cookies.get("accropress_cms_idToken");

  useEffect(() => {
    async function getAndSetCMSPreview() {
      if (pageid && siteid) {
        let AccroPressAPI = new AccroPress.APIProvider({
          baseUrl: "https://api.accropress.com",
          tenantId: siteid
        })

        if (previewCookie) {
          AccroPressAPI = new AccroPress.APIProvider({
            baseUrl: "https://api.accropress.com",
            tenantId: siteid,
            accropress_cms_preview_jwt: previewCookie,
          })
        }

        let content = {}

        if (isRevision !== true && isRevision !== "true") {
          // For published pages
          content = await AccroPressAPI.content.find(pageid)
        } else {
          // For revision pages
          content = await AccroPressAPI.contentRevisions.find(pageid)
        }

        console.log(content)

        setCmsData(content)

        setCmsLoading(false)
      } else if (!previewCookie) {
        setCmsError("No authentication method available.")
      } else {
        setCmsError("No page id was found in the URL.")
      }
    }

    getAndSetCMSPreview()
  }, [])

  return (
    <div className="cms-preview-wrapper">
      {!cmsLoading && !cmsError ? (
        <>
          {cmsData && cmsData.templateID && cmsData.id ? (
            <>
              <div className="cms-preview-topbar">
                {isRevision !== true && isRevision !== "true" ? (
                  <iframe
                    src={`https://healthspan.workspace.accropress.com/dashboard/content-preview/iframe-box/${cmsData.id}`}
                  />
                ) : (
                  <iframe
                    src={`https://healthspan.workspace.accropress.com/dashboard/content-preview/iframe-box/${cmsData.originalContentId}`}
                  />
                )}
              </div>
              <div className="cms-preview-content-wrapper">
                {cmsData.templateID.name === "Homepage" ? (
                  <HomepagePagePage
                    pageContext={{
                      integratorData: {},
                      contentMetaData: cmsData,
                      contentDataData: cmsData.templateJSON,
                      navResultData: {},
                    }}
                  />
                ) : cmsData.templateID.name === "Healthspan Default page" ? (
                  <DefaultPagePage
                    pageContext={{
                      integratorData: {},
                      contentMetaData: cmsData,
                      contentDataData: cmsData.templateJSON,
                      navResultData: {},
                    }}
                  />
                ) : (
                  <p>
                    The template {cmsData.templateID.name} could not be found,
                    please content the dev team to add this template.
                  </p>
                )}
              </div>
            </>
          ) : (
            <p>No template id could be found for this page.</p>
          )}
        </>
      ) : cmsError ? (
        <p>{cmsError}</p>
      ) : (
        <iframe
          id="accropress_cms_preview_loading_frame"
          src="https://app.accropress.com/content-preview-loading.html"
        />
      )}
    </div>
  )
}

export default withLocation(CMSContentPreview)
