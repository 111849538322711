import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link, navigate } from "gatsby"

import fetchJsonp from "fetch-jsonp"

import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"
import config from "../../config.json"

require("es6-promise").polyfill()

export default function HomepagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [categoryFilter, setCategoryFilter] = useState([])
  const [jobTypeFilter, setJobTypeFilter] = useState([])
  const [isIinitialLoad, setIsIinitialLoad] = useState(true)
  //const [vacancyLoading, setVacancyLoading] = useState(true)

  const [keywordsValue, setKeywordsValue] = useState("")
  const [jobtypeValue, setJobtypeValue] = useState("")
  const [categoryValue, setCategoryValue] = useState("")

  let groupOrIdParam = "id"

  function removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b)
  }

  const apiKey = "UAYuqLuj5r7tAvaNW7qSD6CN-xLilM7rkqVED0gmiaY"

  const fetchFilters = async (name, setState, label) => {
    try {
      fetchJsonp(
        "https://sf.vacancy-filler.co.uk/CareerPage/" +
          name +
          "?" +
          "id" +
          "=" +
          apiKey
      )
        .then(function(response) {
          return response.json()
        })
        .then(function(jsonList) {
          // let temp = ["All " + label]
          let temp = ["- Filter by " + label + " -"]

          setState(temp.concat(removeDuplicates(jsonList)))
        })
        .catch(function(ex) {
          console.log(ex, "error")
        })
    } catch (error) {
      console.log(error.message, "error")
    }
  }

  useEffect(() => {
    if (isIinitialLoad) {
      setIsIinitialLoad(false)

      const timeOut = setTimeout(() => {
        //fetchFilters("GetLocations", setLocationFilter, "Locations") // Locations
        //fetchFilters("GetRegions", setRegionFilter, "Region") // Regions
        fetchFilters("GetCategories", setCategoryFilter, "Categories") // Categories
        //fetchFilters("GetkeywordFilter", setKeywordFilter, "Keyword") // Keyword
        //fetchFilters("GetIndustries", setIndustryFilter, "Industries") // Industries
        //fetchFilters("GetPostingTypes", setPostingTypeFilter, "Posting Types") // Posting Types
        fetchFilters("GetJobTypes", setJobTypeFilter, "Job Types") // Job Types
      }, 50)
      return () => clearTimeout(timeOut)
    }
  }, [])

  const searchFormSubmit = data => {
    data.preventDefault()
    console.log("Search form submit => ", data)

    navigate(
      `/vacancies?keywords=${keywordsValue}&jobtype=${jobtypeValue}&category=${categoryValue}`
    )
  }

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <div className="hero-banner-cta block-with-background-image">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-banner-holder">
                <div className="text-block">
                  <div>
                    <h1>
                      Search our
                      <br />
                      latest careers
                    </h1>
                    <div className="page-search-input-group w-100 blog__listing__select">
                      <form
                        className="homepage-search-form"
                        onSubmit={data => searchFormSubmit(data)}
                      >
                        <div className="vf-filter">
                          <label
                            aria-label="- Enter keywords -"
                            htmlFor="select-keywords"
                          >
                            - Enter keywords -:{" "}
                          </label>
                          <input
                            className="form-control"
                            name="keywords"
                            id="select-keywords"
                            placeholder="Enter a keyword"
                            onChange={e =>
                              setKeywordsValue(e.currentTarget.value)
                            }
                          />
                        </div>
                        <div className="vf-filter">
                          <label
                            aria-label="- Filter by Job Type -"
                            htmlFor="select-jobtype"
                          >
                            - Filter by Job Type -:{" "}
                          </label>
                          <select
                            className="form-control"
                            name="jobtype"
                            id="select-jobtype"
                            onChange={e =>
                              setJobtypeValue(e.currentTarget.value)
                            }
                          >
                            {jobTypeFilter.map((item, i) => (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="vf-filter">
                          <label
                            aria-label="- Filter by Category -"
                            htmlFor="select-category"
                          >
                            - Filter by Category -:{" "}
                          </label>
                          <select
                            className="form-control"
                            name="category"
                            id="select-category"
                            onChange={e =>
                              setCategoryValue(e.currentTarget.value)
                            }
                          >
                            {categoryFilter.map((item, i) => (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <button type="submit" className="searchBtn">
                            Search
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-image">
          <img
            src={
              contentDataData[0].children[0] &&
              contentDataData[0].children[0].value
                ? contentDataData[0].children[0].value
                : "/banner.jpg"
            }
            alt="Find your new career at Healthspan"
            width="100%"
            height="auto"
          />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="main-content">
              <h2>{contentDataData[1].children[0].value}</h2>
              <WigLoader
                contentDataData={contentDataData}
                contentMetaData={contentMetaData}
                contentDataArray="1"
                contentDataChild="1"
                AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                integratorID={config.integratorID}
                integratorAccessToken={config.integratorAccessToken}
                imageFormatting={
                  process.env.IMAGE_FORMATTING_SECRET ||
                  process.env.GATSBY_IMAGE_FORMATTING_SECRET
                }
              />
            </div>
          </div>
        </div>

        <div className="blocks three-blocks">
          {contentDataData[2].children[0] &&
          contentDataData[2].children[0].children &&
          contentDataData[2].children[0].children.length > 0 ? (
            <div className="row">
              {contentDataData[2].children[0].children.map((column, i) => (
                <div className="col-12 col-md-4 block" key={i}>
                  <div className="blockBackground">
                    <div
                      className="quickLinkTile"
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom right",
                        backgroundImage: `url(${column[2].value})`,
                      }}
                    >
                      <div className="wrap">
                        <div className="quickLinkText">
                          <h3>{column[0].value}</h3>
                          <Link
                            className="btn-transparent small"
                            to={column[1].value}
                            title={column[0].value}
                          >
                            Find out more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-12">
        <div className="healthspan-spacing">
          <article aria-label="Subscribe for job alerts">
            <div className="ctacard soft">
              <div className="ctacard__body d-flex justify-content-center">
                <div className="col-md-10 col-lg-6 text-center">
                  <h4 className="ctacard__body__title">
                    <span>Can't find a job role to suit you?</span>
                  </h4>
                  <p className="ctacard__body__summary">
                    <span>
                      Get job alerts straight to your inbox and be the first to
                      see our new vacancies.
                    </span>
                  </p>
                  <a className="btn--action" href="/job-alerts/">
                    Subscribe for job alerts
                  </a>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}
